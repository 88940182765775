import { CSSReset, theme, ThemeProvider } from "@chakra-ui/core";
import { Router } from "@reach/router";
import React from "react";
import "./App.css";
import * as constants from "./constants";
import Checkout from "./containers/Checkout";
import CheckoutSuccess from "./containers/CheckoutSuccess";
import Header from "./containers/Header";
import Home from "./containers/Home";
import {
  BasketContext,
  DeliveryContext,
  useBasket,
  useDelivery
} from "./store";

const App = () => {
  const basket = useBasket();
  const delivery = useDelivery();

  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <BasketContext.Provider value={basket}>
        <DeliveryContext.Provider value={delivery}>
          <Router>
            <Header path="/*" />
          </Router>
          <Router>
            <Home path={constants.homeRoute} />
            <CheckoutSuccess path={constants.checkoutSuccessRoute} />
            <Checkout path={constants.checkoutRoute} />
          </Router>
        </DeliveryContext.Provider>
      </BasketContext.Provider>
    </ThemeProvider>
  );
};

export default App;
