import { Box, Flex, PseudoBox, Text } from "@chakra-ui/core";
import React, { useEffect, useState } from "react";
import logo from "../../assets/wtms-logo.png";
import * as constants from "../../constants";

const Header = ({ navigate }) => {
  const [offset, setOffset] = useState(false);

  useEffect(() => {
    const handleScroll = () => setOffset(window.pageYOffset > 0);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <Flex
        align="center"
        as="header"
        backgroundColor="red.600"
        borderBottom={offset === true ? "solid 2px" : ""}
        borderColor="blackAlpha.600"
        justify="center"
        left={0}
        pb={2}
        pt={2}
        position="fixed"
        top={0}
        width="100%"
        zIndex="banner"
      >
        <PseudoBox
          as="img"
          cursor="pointer"
          height={60}
          onClick={() => navigate(constants.homeRoute)}
          src={logo}
        />
        <Text ml={2} fontFamily="tannenberg" fontWeight="normal" fontSize="md">
          Shop
        </Text>
      </Flex>
      <Box backgroundColor="red.600" height="92px" />
    </>
  );
};

export default Header;
