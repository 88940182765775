import * as Sentry from "@sentry/browser";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";

Sentry.init({
  dsn: "https://38bde92bdabd4fb399a84885de884884@sentry.io/5167917"
});

ReactDOM.render(<App />, document.getElementById("root"));
