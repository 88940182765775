import {
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  PseudoBox,
  Spinner,
  Text
} from "@chakra-ui/core";
import { useLocation } from "@reach/router";
import * as Sentry from "@sentry/browser";
import React, { useCallback, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { config } from "../../config";
import * as constants from "../../constants";
import { BasketContext, DeliveryContext } from "../../store";

// https://en.wikipedia.org/wiki/Postcodes_in_the_United_Kingdom#Validation
const postcodeValidation = /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/;

const validatePostcode = value => {
  if (!value) {
    return "Please enter your full postcode";
  }

  try {
    value = value.toUpperCase().trim();
  } catch (e) {
    return "Please enter your full postcode";
  }

  if (value.match(postcodeValidation) === null) {
    return "Please enter your full postcode";
  }

  return true;
};

const validateName = value => {
  if (!value) {
    return "Please enter your name";
  }

  return true;
};

const validateEmail = value => {
  if (!value) {
    return "Please enter your email address";
  }

  if (!value.includes("@")) {
    return "Please enter your email address";
  }

  return true;
};

const validateNumber = value => {
  if (!value) {
    return "Please enter your phone number";
  }

  return true;
};

const validateAddressLine1 = value => {
  if (!value) {
    return "Please enter the first line of your address";
  }

  return true;
};

const validateAddressLine2 = value => {
  return true;
};

const validateTown = value => {
  if (!value) {
    return "Please enter the name of your city or town";
  }

  return true;
};

const formatPrice = price =>
  price === undefined ? undefined : `£${(Math.round(price) / 100).toFixed(2)}`;

const Form = () => {
  const { basket } = useContext(BasketContext);
  const location = useLocation();
  const { postcode, slot } = useContext(DeliveryContext);
  const { handleSubmit, errors, register } = useForm({
    defaultValues: { postcode: postcode, slot: slot }
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);

  const onSubmit = useCallback(
    async values => {
      const stripe = window.Stripe(config.stripeKey);
      setIsSubmitting(true);

      try {
        // This is where I will include all metadata
        const response = await fetch(config.stripeUrl, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            ...values,
            basket,
            successUrl: `${location.origin}${constants.checkoutSuccessRoute}`,
            cancelUrl: `${location.origin}${constants.homeRoute}`
          })
        });
        if (!response.ok) {
          throw new Error(`${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        stripe.redirectToCheckout({ sessionId: data.sessionId });
      } catch (e) {
        setIsSubmitting(false);
        setIsError(true);
        Sentry.captureException(e);
      }
    },
    [basket, location.origin]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text fontFamily="tannenberg" fontSize="2xl" fontWeight="normal" mb={2}>
        Contact information
      </Text>
      <FormControl isInvalid={errors.name} mb={2}>
        <Input
          name="name"
          placeholder="Name"
          ref={register({ validate: validateName })}
          size="sm"
          variant="flushed"
        />
        <FormErrorMessage color="whiteAlpha.800">
          {errors.name && errors.name.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={errors.email} mb={2}>
        <Input
          name="email"
          placeholder="Email"
          ref={register({ validate: validateEmail })}
          size="sm"
          variant="flushed"
        />
        <FormErrorMessage color="whiteAlpha.800">
          {errors.email && errors.email.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={errors.number} mb={2}>
        <Input
          name="number"
          placeholder="Phone number"
          ref={register({ validate: validateNumber })}
          size="sm"
          variant="flushed"
        />
        <FormErrorMessage color="whiteAlpha.800">
          {errors.number && errors.number.message}
        </FormErrorMessage>
      </FormControl>
      <Text
        fontFamily="tannenberg"
        fontSize="2xl"
        fontWeight="normal"
        mb={2}
        mt={8}
      >
        Delivery address
      </Text>
      <FormControl isInvalid={errors.addressLine1} mb={2}>
        <Input
          name="addressLine1"
          placeholder="Address line one"
          ref={register({ validate: validateAddressLine1 })}
          size="sm"
          variant="flushed"
        />
        <FormErrorMessage color="whiteAlpha.800">
          {errors.addressLine1 && errors.addressLine1.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={errors.addressLine2} mb={2}>
        <Input
          name="addressLine2"
          placeholder="Address line two (optional)"
          ref={register({ validate: validateAddressLine2 })}
          size="sm"
          variant="flushed"
        />
        <FormErrorMessage color="whiteAlpha.800">
          {errors.addressLine2 && errors.addressLine2.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={errors.town} mb={2}>
        <Input
          name="town"
          placeholder="Town or city"
          ref={register({ validate: validateTown })}
          size="sm"
          variant="flushed"
        />
        <FormErrorMessage color="whiteAlpha.800">
          {errors.town && errors.town.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={errors.postcode} mb={2}>
        <Input
          name="postcode"
          placeholder="Postcode"
          ref={register({ validate: validatePostcode })}
          size="sm"
          variant="flushed"
        />
        <FormErrorMessage color="whiteAlpha.800">
          {errors.postcode && errors.postcode.message}
        </FormErrorMessage>
      </FormControl>
      {!isError && (
        <PseudoBox
          alignItems="center"
          as={Flex}
          borderColor="blackAlpha.600"
          borderRadius={4}
          borderWidth={2}
          cursor="pointer"
          flexGrow={1}
          justifyContent="center"
          mt={12}
          onClick={handleSubmit(onSubmit)}
          p={2}
          _hover={{ borderColor: "whiteAlpha.800" }}
        >
          {isSubmitting && <Spinner size="sm" />}
          {!isSubmitting && (
            <Text fontSize="sm" textAlign="center">
              {`Pay ${formatPrice(
                basket.reduce((total, item) => total + item.price, 0)
              )}`}
            </Text>
          )}
        </PseudoBox>
      )}
      {isError && !isSubmitting && (
        <Text fontSize="sm" mt="56px" textAlign="center">
          Sorry, folks. Something went wrong whilst taking payment. Give us a
          call and we'll get you sorted.
        </Text>
      )}
    </form>
  );
};

export default Form;
