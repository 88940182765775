import { Box, Flex, PseudoBox, Text } from "@chakra-ui/core";
import { useNavigate } from "@reach/router";
import React, { useCallback, useContext, useEffect, useState } from "react";
import * as constants from "../../constants";
import { BasketContext } from "../../store";

const formatPrice = price =>
  price === undefined ? undefined : `£${(Math.round(price) / 100).toFixed(2)}`;

const Item = ({ description, isSelected, onClick, onRemove, price, title }) => {
  if (isSelected) {
    return (
      <Flex justify="center" mt={2}>
        <PseudoBox
          as={Box}
          borderColor="blackAlpha.600"
          borderRadius={4}
          borderWidth={2}
          cursor="pointer"
          flexGrow={1}
          mr={1}
          onClick={onClick}
          p={2}
          _hover={{ borderColor: "whiteAlpha.800" }}
        >
          <Flex justify="space-between">
            <Text fontSize="sm" fontWeight="bold" textAlign="left">
              {title}
            </Text>
            <Text fontSize="sm" fontWeight="bold" textAlign="left">
              {formatPrice(price)}
            </Text>
          </Flex>
          <Text fontSize="sm" textAlign="left">
            {description}
          </Text>
        </PseudoBox>
        <Box
          borderColor="whiteAlpha.800"
          borderRadius={4}
          borderWidth={2}
          cursor="pointer"
          flexGrow={1}
          ml={1}
          onClick={onRemove}
          p={2}
        >
          <Text color="whiteAlpha.800" fontSize="sm" textAlign="center">
            Remove from basket
          </Text>
        </Box>
      </Flex>
    );
  }

  return (
    <PseudoBox
      as={Box}
      borderColor="blackAlpha.600"
      borderRadius={4}
      borderWidth={2}
      cursor={onClick && "pointer"}
      onClick={onClick}
      p={2}
      mt={2}
      _hover={onClick && { borderColor: "whiteAlpha.800" }}
    >
      <Flex justify="space-between">
        <Text fontSize="sm" fontWeight="bold" textAlign="left">
          {title}
        </Text>
        <Text fontSize="sm" fontWeight="bold" textAlign="left">
          {formatPrice(price)}
        </Text>
      </Flex>
      <Text fontSize="sm" textAlign="left">
        {description}
      </Text>
    </PseudoBox>
  );
};

const Summary = ({ onMenu }) => {
  const navigate = useNavigate();
  const handleCheckout = useCallback(() => navigate(constants.checkoutRoute), [
    navigate
  ]);

  const { basket, removeFromBasket } = useContext(BasketContext);

  const [selected, setSelected] = useState(basket.map(() => false));
  const handleSelected = useCallback(
    selected =>
      setSelected(prevSelected =>
        basket.map((time, index) =>
          selected === index ? !prevSelected[index] : false
        )
      ),
    [basket]
  );

  useEffect(() => {
    setSelected(basket.map(() => false));
  }, [basket]);

  return (
    <Box>
      {basket
        .filter(item => !item.required)
        .map((item, index) => {
          return (
            <Item
              description={item.description}
              key={index}
              isSelected={selected[index]}
              onClick={() => handleSelected(index)}
              onRemove={() => removeFromBasket(index)}
              price={item.price}
              title={item.title}
            />
          );
        })}
      {basket
        .filter(item => item.required)
        .map((item, index) => {
          return (
            <Item
              description={item.description}
              key={index}
              price={item.price}
              title={item.title}
            />
          );
        })}
      <Flex justify="center" mt={4}>
        <PseudoBox
          as={Box}
          borderColor="blackAlpha.600"
          borderRadius={4}
          borderWidth={2}
          cursor="pointer"
          flexGrow={1}
          mr={1}
          onClick={onMenu}
          p={2}
          _hover={{ borderColor: "whiteAlpha.800" }}
        >
          <Text fontSize="sm" textAlign="center">
            Back to menu
          </Text>
        </PseudoBox>
        <Box
          borderColor="whiteAlpha.800"
          borderRadius={4}
          borderWidth={2}
          cursor="pointer"
          flexGrow={1}
          ml={1}
          onClick={handleCheckout}
          p={2}
        >
          <Text color="whiteAlpha.800" fontSize="sm" textAlign="center">
            Checkout for{" "}
            {formatPrice(basket.reduce((total, item) => total + item.price, 0))}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default Summary;
