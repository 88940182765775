import { createContext, useCallback, useState } from "react";

const DeliveryContext = createContext({
  setInfo: () => {},
  postcode: undefined,
  slot: undefined
});

const useDelivery = () => {
  const [postcode, setPostcode] = useState();
  const [slot, setSlot] = useState();

  const setInfo = useCallback((postcode, slot) => {
    setPostcode(postcode);
    setSlot(slot);
  }, []);

  return { postcode, slot, setInfo };
};

// Note that the API of the default value in DeliveryContext should match the API
// returned by useDelivery.
export { DeliveryContext, useDelivery };
