import { Box, Flex, Text } from "@chakra-ui/core";
import React from "react";
import { FiMail, FiPhone } from "react-icons/fi";

const Contact = () => {
  return (
    <Flex align="flex-start" justify="space-around" mb={8} mt={8} wrap="wrap">
      <Flex
        align="center"
        as="a"
        direction="column"
        href="tel:+441412045260"
        mt={4}
        mb={4}
        ml={2}
        mr={2}
      >
        <Text
          fontFamily="tannenberg"
          fontSize="md"
          fontWeight="normal"
          textAlign="center"
        >
          Argyle Street
        </Text>
        <Flex align="center">
          <Box as={FiPhone} mr={1} />
          <Text fontSize="sm">0141 204 5260</Text>
        </Flex>
        <Flex align="center">
          <Box as={FiMail} mr={1} />
          <Text fontSize="sm">info@monkeysleeps.com</Text>
        </Flex>
      </Flex>
      <Flex
        align="center"
        as="a"
        direction="column"
        href="tel:+441412263406"
        mt={4}
        mb={4}
        ml={2}
        mr={2}
      >
        <Text
          fontFamily="tannenberg"
          fontSize="md"
          fontWeight="normal"
          textAlign="center"
        >
          West Regent Street
        </Text>
        <Flex align="center">
          <Text fontSize="sm">Closed</Text>
        </Flex>
      </Flex>
      <Flex
        align="center"
        as="a"
        direction="column"
        href="mailto:info@monkeysleeps.com"
        mt={4}
        mb={4}
        ml={2}
        mr={2}
      >
        <Text
          fontFamily="tannenberg"
          fontSize="md"
          fontWeight="normal"
          textAlign="center"
        >
          Templeton Green
        </Text>
        <Flex align="center">
          <Text fontSize="sm">Closed</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Contact;
