import { Box, Flex, Text } from "@chakra-ui/core";
import { useNavigate } from "@reach/router";
import React from "react";
import * as constants from "../../constants";
import Contact from "../../views/Contact";

const CheckoutSuccess = () => {
  const navigate = useNavigate();
  return (
    <Flex as="main" backgroundColor="red.600" justify="center">
      <Flex
        align="stretch"
        direction="column"
        justify="space-between"
        minHeight="calc(100vh - 92px)"
        paddingLeft={[4, 0, 0, 0]}
        paddingRight={[4, 0, 0, 0]}
        width={[
          "100%", // base
          "448px", // 480px upwards
          "448px", // 768px upwards
          "448px" // 992px upwards
        ]}
      >
        <Box>
          <Text fontSize="sm" mt="56px" textAlign="center">
            Thanks, folks! We've received your order. We'll be in touch when
            it's out for delivery.
          </Text>
          <Box
            borderColor="whiteAlpha.800"
            borderRadius={4}
            borderWidth={2}
            cursor="pointer"
            // flexGrow={1}
            mt={4}
            onClick={() => navigate(constants.homeRoute)}
            p={2}
          >
            <Text color="whiteAlpha.800" fontSize="sm" textAlign="center">
              Home
            </Text>
          </Box>
        </Box>
        <Contact />
      </Flex>
    </Flex>
  );
};

export default CheckoutSuccess;
