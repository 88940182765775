import { Flex } from "@chakra-ui/core";
import React from "react";
import Contact from "../../views/Contact";
import Form from "./Form";

const Checkout = () => {
  return (
    <Flex as="main" backgroundColor="red.600" justify="center">
      <Flex
        align="stretch"
        direction="column"
        justify="space-between"
        minHeight="calc(100vh - 92px)"
        paddingLeft={[4, 0, 0, 0]}
        paddingRight={[4, 0, 0, 0]}
        width={[
          "100%", // base
          "448px", // 480px upwards
          "448px", // 768px upwards
          "448px" // 992px upwards
        ]}
      >
        <Form />
        <Contact />
      </Flex>
    </Flex>
  );
};

export default Checkout;
