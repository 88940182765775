import { Flex } from "@chakra-ui/core";
import { useNavigate } from "@reach/router";
import React, { useCallback, useState } from "react";
import * as constants from "../../constants";
import Contact from "../../views/Contact";
import Cart from "./Cart";
import Items from "./Items";
import Summary from "./Summary";

const PageOne = ({ next }) => {
  return (
    <>
      <Items onBasket={next} />
      <Contact />
    </>
  );
};

const PageTwo = ({ next, prev }) => {
  return (
    <>
      <Summary onMenu={prev} />
      <Contact />
    </>
  );
};

const pages = [PageOne, PageTwo];

const Menu = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const nextPage = useCallback(
    () => setPage(p => (p < pages.length - 1 ? p + 1 : p)),
    []
  );
  const prevPage = useCallback(() => setPage(p => (p > 0 ? p - 1 : p)), []);

  return (
    <Flex as="main" backgroundColor="red.600" justify="center">
      <Flex
        align="stretch"
        direction="column"
        justify="space-between"
        minHeight="calc(100vh - 92px)"
        paddingLeft={[4, 0, 0, 0]}
        paddingRight={[4, 0, 0, 0]}
        width={[
          "100%", // base
          "448px", // 480px upwards
          "448px", // 768px upwards
          "448px" // 992px upwards
        ]}
      >
        {page === 0 && <Cart onClick={nextPage} />}
        {page === 0 && <PageOne next={nextPage} />}
        {page === 1 && (
          <PageTwo
            next={() => navigate(constants.checkoutRoute)}
            prev={prevPage}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default Menu;
