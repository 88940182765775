import { Box, Flex, Text } from "@chakra-ui/core";
import React, { useContext } from "react";
import { FiShoppingCart } from "react-icons/fi";
import { BasketContext } from "../../store";

const formatPrice = price =>
  price === undefined ? undefined : `£${(Math.round(price) / 100).toFixed(2)}`;

const Cart = ({ onClick }) => {
  const { basket } = useContext(BasketContext);

  if (basket.length === 0) {
    return null;
  }

  return (
    <Box
      bottom={12}
      pointerEvents="none"
      position="fixed"
      transform="translateX(100%) translateX(-82px);"
      width={[
        "calc(100% - 2rem)", // base
        "448px", // 480px upwards
        "448px", // 768px upwards
        "448px" // 992px upwards
      ]}
    >
      <Flex
        align="center"
        backgroundColor="gray.100"
        borderRadius="50%"
        cursor="pointer"
        direction="column"
        height="3.5rem"
        justify="center"
        onClick={onClick}
        pointerEvents="auto"
        width="3.5rem"
        // Taken from MaterialUI
        shadow="0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)"
        bottom={8}
        zIndex={100}
      >
        <Box as={FiShoppingCart} />
        <Text fontSize="sm" fontWeight="normal">
          {formatPrice(basket.reduce((total, item) => total + item.price, 0))}
        </Text>
      </Flex>
    </Box>
  );
};

export default Cart;
