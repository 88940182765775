import isEqual from "lodash/isEqual";
import { createContext, useCallback, useState } from "react";

const BasketContext = createContext({
  addToBasket: () => {},
  basket: [],
  removeFromBasket: () => {}
});

const delivery = { title: "Delivery", price: 250, required: true };

const useBasket = () => {
  const [basket, setBasket] = useState([]);

  const addToBasket = useCallback(
    item =>
      setBasket(prevBasket => [
        ...prevBasket.filter(i => !isEqual(i, delivery)),
        item,
        delivery
      ]),
    []
  );

  const removeFromBasket = useCallback(
    index =>
      setBasket(prevBasket => {
        const newBasket = prevBasket.filter((item, i) => i !== index);

        // In this case, the only item must be the delivery cost.
        if (newBasket.length === 1 && isEqual(newBasket[0], delivery)) {
          return [];
        }

        return newBasket;
      }),
    []
  );

  return { addToBasket, basket, removeFromBasket };
};

// Note that the API of the default value in BasketContext should match the API
// returned by useBasket.
export { BasketContext, useBasket };
