import {
  Box,
  Flex,
  PseudoBox,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from "@chakra-ui/core";
import isEqual from "lodash/isEqual";
import React, { useCallback, useContext, useEffect, useState } from "react";
import hoodieBlack from "../../assets/hoodie-black.png";
import hoodieRed from "../../assets/hoodie-red.png";
import keep12oz from "../../assets/keep-12oz.png";
import keep8oz from "../../assets/keep-8oz.png";
import toteBlack from "../../assets/tote-black.png";
import toteRed from "../../assets/tote-red.png";
import tshirtBlack from "../../assets/tshirt-black.png";
import tshirtRed from "../../assets/tshirt-red.png";
import { BasketContext } from "../../store";

const formatPrice = price =>
  price === undefined ? undefined : `£${(Math.round(price) / 100).toFixed(2)}`;

const Item = ({ isSelected, item, onClick, onConfirm }) => {
  const [isConfirmed, setIsConfirmed] = useState(false);

  // Store selected options
  const [selected, setSelected] = useState();

  const handleSelected = useCallback(
    (i, j) => {
      if (item === undefined) {
        return;
      }

      if (item.options === undefined || item.options.length === 0) {
        return;
      }

      setSelected(selected =>
        item.options.map((option, optionIndex) =>
          option.children.map((choice, choiceIndex) =>
            optionIndex === i && choiceIndex === j ? !selected[i][j] : false
          )
        )
      );
    },
    [item]
  );

  useEffect(() => {
    if (item === undefined) {
      return;
    }

    if (item.options === undefined || item.options.length === 0) {
      return;
    }

    setSelected(item.options.map(option => option.children.map(() => false)));
  }, [item]);

  // In order that we can order the same item twice, we need to "reset" the
  // confirmation. Note that this requires the customer to select another
  // item first - it would be nice to find a better solution to this.
  useEffect(() => {
    setIsConfirmed(false);

    if (item === undefined) {
      return;
    }

    if (item.options === undefined || item.options.length === 0) {
      return;
    }

    setSelected(item.options.map(option => option.children.map(() => false)));
  }, [isSelected, item]);

  if (item === undefined) {
    return null;
  }

  if (!isSelected && item.image !== undefined) {
    return (
      <Flex>
        <PseudoBox
          as={Box}
          borderColor="blackAlpha.600"
          borderRadius={4}
          borderWidth={2}
          cursor="pointer"
          flexGrow={1}
          onClick={onClick}
          p={2}
          mt={2}
          _hover={{ borderColor: "whiteAlpha.800" }}
        >
          <Flex justify="space-between">
            <Text fontSize="sm" fontWeight="bold" textAlign="left">
              {item.title}
            </Text>
            <Text fontSize="sm" fontWeight="bold" textAlign="left">
              {formatPrice(item.price)}
            </Text>
          </Flex>
          <Text fontSize="sm" textAlign="left">
            {item.description}
          </Text>
        </PseudoBox>
        {item.image && (
          <Box borderRadius="inherit" mt={2} ml={2}>
            <PseudoBox
              alt="Merchandise"
              as="img"
              borderRadius={4}
              src={item.image}
              width="140px"
            />
          </Box>
        )}
      </Flex>
    );
  }

  if (!isSelected && item.image === undefined) {
    return (
      <PseudoBox
        as={Box}
        borderColor="blackAlpha.600"
        borderRadius={4}
        borderWidth={2}
        cursor="pointer"
        flexGrow={1}
        onClick={onClick}
        p={2}
        mt={2}
        _hover={{ borderColor: "whiteAlpha.800" }}
      >
        <Flex justify="space-between">
          <Text fontSize="sm" fontWeight="bold" textAlign="left">
            {item.title}
          </Text>
          <Text fontSize="sm" fontWeight="bold" textAlign="left">
            {formatPrice(item.price)}
          </Text>
        </Flex>
        <Text fontSize="sm" textAlign="left">
          {item.description}
        </Text>
      </PseudoBox>
    );
  }

  if (
    isSelected &&
    item.image === undefined &&
    (item.options === undefined || item.options.length === 0)
  ) {
    return (
      <Flex justify="center" mt={2}>
        <PseudoBox
          as={Box}
          borderColor="blackAlpha.600"
          borderRadius={4}
          borderWidth={2}
          cursor="pointer"
          flexGrow={1}
          mr={1}
          onClick={onClick}
          p={2}
          _hover={{ borderColor: "whiteAlpha.800" }}
        >
          <Flex justify="space-between">
            <Text fontSize="sm" fontWeight="bold" textAlign="left">
              {item.title}
            </Text>
            <Text fontSize="sm" fontWeight="bold" textAlign="left">
              {formatPrice(item.price)}
            </Text>
          </Flex>
          <Text fontSize="sm" textAlign="left">
            {item.description}
          </Text>
        </PseudoBox>
        {!isConfirmed && (
          <Box
            borderColor="whiteAlpha.800"
            borderRadius={4}
            borderWidth={2}
            cursor="pointer"
            flexGrow={1}
            ml={1}
            onClick={() => {
              onConfirm();
              setIsConfirmed(true);
            }}
            p={2}
          >
            <Text color="whiteAlpha.800" fontSize="sm" textAlign="center">
              Add to basket
            </Text>
          </Box>
        )}
        {isConfirmed && (
          <Box
            borderColor="green.400"
            borderRadius={4}
            borderWidth={2}
            cursor="normal"
            flexGrow={1}
            ml={1}
            p={2}
          >
            <Text color="green.400" fontSize="sm" textAlign="center">
              Added to basket
            </Text>
          </Box>
        )}
      </Flex>
    );
  }

  if (
    isSelected &&
    item.image !== undefined &&
    (item.options === undefined || item.options.length === 0)
  ) {
    return (
      <Flex>
        <Flex flexGrow={1} direction="column" justify="center" mt={2}>
          <PseudoBox
            as={Box}
            borderColor="blackAlpha.600"
            borderRadius={4}
            borderWidth={2}
            cursor="pointer"
            flexGrow={1}
            mb={1}
            onClick={onClick}
            p={2}
            _hover={{ borderColor: "whiteAlpha.800" }}
          >
            <Flex justify="space-between">
              <Text fontSize="sm" fontWeight="bold" textAlign="left">
                {item.title}
              </Text>
              <Text fontSize="sm" fontWeight="bold" textAlign="left">
                {formatPrice(item.price)}
              </Text>
            </Flex>
            <Text fontSize="sm" textAlign="left">
              {item.description}
            </Text>
          </PseudoBox>
          {!isConfirmed && (
            <Box
              borderColor="whiteAlpha.800"
              borderRadius={4}
              borderWidth={2}
              cursor="pointer"
              flexGrow={1}
              mt={1}
              onClick={() => {
                onConfirm();
                setIsConfirmed(true);
              }}
              p={2}
            >
              <Text color="whiteAlpha.800" fontSize="sm" textAlign="center">
                Add to basket
              </Text>
            </Box>
          )}
          {isConfirmed && (
            <Box
              borderColor="green.400"
              borderRadius={4}
              borderWidth={2}
              cursor="normal"
              flexGrow={1}
              mt={1}
              p={2}
            >
              <Text color="green.400" fontSize="sm" textAlign="center">
                Added to basket
              </Text>
            </Box>
          )}
        </Flex>
        <Box borderRadius="inherit" mt={2} ml={2}>
          <PseudoBox
            alt="Black t-shirt"
            as="img"
            borderRadius={4}
            src={item.image}
            width="140px"
          />
        </Box>
      </Flex>
    );
  }

  return (
    <Flex>
      <Flex flexGrow={1} direction="column" justify="center" mt={2}>
        <PseudoBox
          as={Box}
          borderColor="blackAlpha.600"
          borderRadius={4}
          borderWidth={2}
          flexGrow={1}
          onClick={onClick}
          p={2}
        >
          <Flex justify="space-between">
            <Text fontSize="sm" fontWeight="bold" textAlign="left">
              {item.title}
            </Text>
            <Text fontSize="sm" fontWeight="bold" textAlign="left">
              {formatPrice(item.price)}
            </Text>
          </Flex>
          <Text fontSize="sm" textAlign="left">
            {item.description}
          </Text>
        </PseudoBox>
        {item.options.map((option, optionIndex) => {
          return (
            <Flex key={optionIndex} mt={2}>
              {option.children.map((choice, choiceIndex) => {
                return (
                  <PseudoBox
                    as={Box}
                    borderColor={
                      selected[optionIndex][choiceIndex] === true
                        ? "whiteAlpha.800"
                        : "blackAlpha.600"
                    }
                    borderRadius={4}
                    borderWidth={2}
                    cursor="pointer"
                    flexGrow={1}
                    key={choiceIndex}
                    ml={choiceIndex > 0 ? 1 : 0}
                    mr={choiceIndex < option.children.length - 1 ? 1 : 0}
                    onClick={() => handleSelected(optionIndex, choiceIndex)}
                    p={2}
                    _hover={{ borderColor: "whiteAlpha.800" }}
                  >
                    <Text fontSize="sm" fontWeight="bold" textAlign="center">
                      {choice.title}
                    </Text>
                  </PseudoBox>
                );
              })}
            </Flex>
          );
        })}
        {selected.every(
          option => option.some(choice => choice === true) === true
        ) &&
          !isConfirmed && (
            <Box
              borderColor="whiteAlpha.800"
              borderRadius={4}
              borderWidth={2}
              cursor="pointer"
              flexGrow={1}
              mt={2}
              onClick={() => {
                // The way that we manage options in the shop isn't suitable
                // for the backend (yet!). As such, we work around it for the
                // timebeing.
                onConfirm({
                  ...item,
                  options: undefined,
                  title: `${item.title} - ${item.options
                    .map(
                      (option, optionIndex) =>
                        `${option.title} ${option.children
                          .filter(
                            (choice, choiceIndex) =>
                              selected[optionIndex][choiceIndex]
                          )
                          .map(choice => choice.title)
                          .join("")}`
                    )
                    .join(", ")}`
                });
                setIsConfirmed(true);
              }}
              p={2}
            >
              <Text color="whiteAlpha.800" fontSize="sm" textAlign="center">
                Add to basket
              </Text>
            </Box>
          )}
        {selected.every(
          option => option.some(choice => choice === true) === true
        ) &&
          isConfirmed && (
            <Box
              borderColor="green.400"
              borderRadius={4}
              borderWidth={2}
              cursor="normal"
              flexGrow={1}
              mt={2}
              p={2}
            >
              <Text color="green.400" fontSize="sm" textAlign="center">
                Added to basket
              </Text>
            </Box>
          )}
      </Flex>
      {item.image && (
        <Box borderRadius="inherit" mt={2} ml={2}>
          <PseudoBox
            alt="Merchandise"
            as="img"
            borderRadius={4}
            src={item.image}
            width="140px"
          />
        </Box>
      )}
    </Flex>
  );
};

const menu = [
  {
    title: "Merchandise",
    children: [
      {
        title: "T-shirt (black)",
        description: "Represent, you cheeky monkey!",
        price: 1500,
        image: tshirtBlack,
        options: [
          {
            title: "Size",
            children: [
              { title: "S" },
              { title: "M" },
              { title: "L" },
              { title: "XL" }
            ]
          }
        ]
      },
      {
        title: "T-shirt (red)",
        description: "Represent, you cheeky monkey!",
        price: 1500,
        image: tshirtRed,
        options: [
          {
            title: "Size",
            children: [
              { title: "S" },
              { title: "M" },
              { title: "L" },
              { title: "XL" }
            ]
          }
        ]
      },
      {
        title: "Hoodie (black)",
        description: "Represent, you cheeky monkey!",
        price: 2500,
        image: hoodieBlack,
        options: [
          {
            title: "Size",
            children: [
              { title: "S" },
              { title: "M" },
              { title: "L" },
              { title: "XL" }
            ]
          }
        ]
      },
      {
        title: "Hoodie (red)",
        description: "Represent, you cheeky monkey!",
        price: 2500,
        image: hoodieRed,
        options: [
          {
            title: "Size",
            children: [
              { title: "S" },
              { title: "M" },
              { title: "L" },
              { title: "XL" }
            ]
          }
        ]
      },
      {
        title: "Tote bag (black)",
        description: "Good for more than just sandwiches",
        price: 600,
        image: toteBlack
      },
      {
        title: "Tote bag (red)",
        description: "Good for more than just sandwiches",
        price: 600,
        image: toteRed
      },
      {
        title: "Keep cup (8oz)",
        description: "Save the world, look good whilst doing it",
        price: 1200,
        image: keep8oz
      },
      {
        title: "Keep cup (12oz)",
        description: "Save the world, look good whilst doing it",
        price: 1400,
        image: keep12oz
      }
    ]
  },
  {
    title: "Subscriptions",
    children: [
      {
        title: "Tasty Tuesdays (one month)",
        description:
          "Enjoy any of our tasty food options every Tuesday for a whole month including a monthly exclusive subscriber sandwich!",
        price: 2000
      },
      {
        title: "Tasty Tuesdays (two months)",
        description:
          "Enjoy any of our tasty food options every Tuesday for a whole month including a monthly exclusive subscriber sandwich!",
        price: 4000
      },
      {
        title: "Tasty Tuesdays (three months)",
        description:
          "Enjoy any of our tasty food options every Tuesday for a whole month including a monthly exclusive subscriber sandwich!",
        price: 6000
      }
    ]
  }
];

const Menu = () => {
  // Selection
  const [selected, setSelected] = useState();

  const handleSelected = useCallback((i, j) => {
    if (isEqual(menu, {})) {
      return;
    }

    setSelected(selected =>
      menu.map((section, sectionIndex) =>
        section.children.map((item, itemIndex) =>
          sectionIndex === i && itemIndex === j ? !selected[i][j] : false
        )
      )
    );
  }, []);

  useEffect(() => {
    if (isEqual(menu, {})) {
      return;
    }

    setSelected(menu.map(section => section.children.map(() => false)));
  }, []);

  // Basket
  const { addToBasket } = useContext(BasketContext);

  return (
    <>
      <Box>
        <Tabs isFitted size="sm">
          <TabList borderColor="blackAlpha.600" flexWrap="wrap">
            {menu.map((section, index) => (
              <Tab
                key={index}
                _selected={{
                  borderBottom: "2px solid",
                  borderColor: "whiteAlpha.800",
                  color: "whiteAlpha.800"
                }}
                _focus={{ outline: "none" }}
              >
                {section.title}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {menu.map((section, sectionIndex) => {
              return (
                <TabPanel key={sectionIndex}>
                  {section.children.map((item, itemIndex) => {
                    return (
                      <Item
                        isSelected={
                          selected !== undefined
                            ? selected[sectionIndex][itemIndex]
                            : false
                        }
                        item={item}
                        key={itemIndex}
                        onClick={() => handleSelected(sectionIndex, itemIndex)}
                        onConfirm={customisedItem => {
                          if (customisedItem === undefined) {
                            return addToBasket(item);
                          }

                          return addToBasket(customisedItem);
                        }}
                      />
                    );
                  })}
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
};

export default Menu;
